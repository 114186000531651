// extracted by mini-css-extract-plugin
export var caseContactFormSection = "br_k4";
export var caseStudyBackground__lineColor = "br_kW";
export var caseStudyHead = "br_k6";
export var caseStudyHead__imageWrapper = "br_kT";
export var caseStudyProjectsSection = "br_k5";
export var caseStudyQuote__bgLight = "br_k2";
export var caseStudyQuote__bgRing = "br_k1";
export var caseStudyVideo__ring = "br_k8";
export var caseStudy__bgDark = "br_kS";
export var caseStudy__bgLight = "br_kR";